import { graphql } from 'gatsby';
import React from 'react'
import BaseHead from '../../components/BaseHead/BaseHead';
import Search from '../zoeken'

const search = (props) => <Search {...props} language="EN" />

export const query = graphql`
  query SearchQueryEN {
    topMenu: cms {
      menuByName(name: "top", language: EN) {
        links {
          label
          url {
            path
          }
        }
      }
    }

    footerMenu: cms {
      menuByName(name: "footer", language: EN) {
        links {
          ... Link
          links {
            ... Link
          }
        }
      }
    }
  }
`

export default search

const SearchHead = () => {
  return (
    <BaseHead>
      <title>Search | DigiD</title>
    </BaseHead>
  )
}

export const Head = SearchHead
